import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "frequently-asked-questions"
    }}>{`Frequently Asked Questions`}</h2>
    <ul>
      <li parentName="ul"><Link to="/first-class-info" mdxType="Link">What do I need to know for my first class?</Link></li>
      <li parentName="ul"><Link to="/schedule" mdxType="Link">Schedule of Classes</Link></li>
      <li parentName="ul"><Link to="/holidays" mdxType="Link">Holiday Closings</Link></li>
      <li parentName="ul"><Link to="/policies" mdxType="Link">School Policies</Link></li>
      <li parentName="ul"><Link to="/dues" mdxType="Link">School Dues</Link></li>
    </ul>
    <h2 {...{
      "id": "about-tai-chi-usa"
    }}>{`About Tai Chi USA`}</h2>
    <ul>
      <li parentName="ul">{`Teacher Bios:`}<ul parentName="li">
          <li parentName="ul"><Link to="/andy-lee" mdxType="Link">Andy Lee</Link>, Chief Instructor</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      