/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/layout'
import SEO from '../components/seo'

const MDXLayout = ({ children, pageContext }) => {
  const { frontmatter = {} } = pageContext
  const { title } = frontmatter

  return (
    <Layout>
      <SEO title={title} />
      <div>{children}</div>
    </Layout>
  )
}

export default MDXLayout
